import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Caption from "src/components/flow/caption"
import Menu from "src/components/flow/menu"
import Flow from "src/components/flow/flow"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Caption />
      <Menu />
      <Flow />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
