import React from "react"
import Styles from "./menu.module.scss"
import Wrap from "src/components/wrap"
import PublisherImg from "src/images/flow_ready_publisher@2x.png"
import ConsumerImg from "src/images/flow_ready_consumer@2x.png"

const MenuItem = ({ imgSrc, imgAlt, title, tagList, linkTo }) => (
  <div className={`column is-half is-flex-tablet ${Styles.item}`}>
    <a className="box has-text-centered" href={linkTo} style={{ backgroundColor: "#FFC72E", width: "100%", height: "100%", border: "solid 2px white" }}>
      <div className="columns is-vcentered has-text-left">
        <div className="column is-5 is-paddingless">
          <figure className="image is-hidden-tablet" style={{ maxWidth: "64px", margin: ".5rem auto" }}><img src={imgSrc} alt={imgAlt} /></figure>
          <figure className="image is-hidden-mobile" style={{ maxWidth: "128px", margin: "0 auto" }}><img src={imgSrc} alt={imgAlt} /></figure>
        </div>
        <div className="column is-paddingless">
          <div className="content has-text-centered-mobile has-text-weight-bold">
            <div className="is-size-7-mobile is-marginless">{title}</div>
            <div className="is-hidden-mobile" style={{ width: "100%", height: "10px" }}></div>
            <div style={{ display: "inline-block" }}>
              <div className="tags is-marginless">
                {tagList.map(tag => <span className={`tag is-rounded has-text-info has-background-white ${Styles.tag}`} key={tag}>{tag}</span>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
)

const MenuColumn = ({children}) => (
  <div className="columns is-mobile">
    {children}
  </div>
)

const Menu = () => (
  <div className="section" style={{ marginTop: "-2rem" }}>
    <div className="container">
      <MenuColumn>
        <MenuItem
          imgSrc={PublisherImg}
          imgAlt="Publisher"
          title={<Wrap><span>コンテンツを</span><span>配信する</span></Wrap>}
          tagList={["管理者", "開発者"]}
          linkTo="#publisher"
        />
        <MenuItem
          imgSrc={ConsumerImg}
          imgAlt="Consumer"
          title={<Wrap><span>natadeCOCO</span><span>スポットを</span><span>探す</span></Wrap>}
          tagList={["ゲスト"]}
          linkTo="#consumer"
        />
      </MenuColumn>
    </div>
  </div>
)

export default Menu