import React from "react"
import Styles from "./flow.module.scss"
import Mainsection from "src/components/section/main"
import Subsection from "src/components/section/sub"
import ColorBox from "src/components/ColorBox"
import Spacer from "src/components/spacer"
import Wrap from "src/components/wrap"
import AppInstall from "src/components/install"
import ConsoleQR from "src/images/qr_console.png"
import PublisherStep2PC1 from "src/images/flow_publisher_step2_pc_1@2x.png"
import PublisherStep2PC2 from "src/images/flow_publisher_step2_pc_2@2x.png"
import PublisherStep2Mobile1 from "src/images/flow_publisher_step2_mobile_1@2x.png"
import PublisherStep2Mobile2 from "src/images/flow_publisher_step2_mobile_2@2x.png"
import PublisherStep4 from "src/images/flow_publisher_step4@2x.png"
import PublisherStep5 from "src/images/flow_publisher_step5@2x.png"
import ConsumerStep2 from "src/images/flow_consumer_step2@2x.png"
import ConsumerStep3 from "src/images/flow_consumer_step3@2x.png"
import ConsumerStep4_1 from "src/images/flow_consumer_step4_1@2x.png"
import ConsumerStep4_2 from "src/images/flow_consumer_step4_2@2x.png"
import ConsumerStep4_3 from "src/images/flow_consumer_step4_3@2x.png"
import ConsumerStep4_4 from "src/images/flow_consumer_step4_4@2x.png"

//////////////////// ご用意いただくもの ////////////////////

const RequiredSection = ({ children }) => (
  <div className="box is-shadowless">
    <Subsection title="ご用意いただくもの">
      <div className="content"><p>以下のものをあらかじめご用意ください。</p></div>
      <div className="columns has-text-centered">
        {children}
      </div>
    </Subsection>
  </div>
)

const RequiredItem = ({ name, label, icon, size }) => (
  <div className={`column ${size} is-flex-mobile`} style={{alignItems: "center"}}>
    <Spacer isSmall className="is-hidden-mobile" />
    <div className="icon is-size-5 is-size-7-mobile is-large has-text-primary" style={{ padding: "1rem 2rem" }}><i className={`${icon} fa-3x`} /></div>
    <Spacer isSmall className="is-hidden-mobile" />
    <div>
      <div className="has-text-weight-bold has-text-left-mobile">{name}</div>
      {label && <div className="has-text-left-mobile has-text-grey is-size-7">({label})</div>}
    </div>
  </div>
)

//////////////////// ご利用の流れ パーツ ////////////////////

const Step = ({ title, step, children }) => (
  <div>
    <div className="columns is-mobile is-vcentered">
      <div className="column is-narrow">
        <div className="is-inline-flex has-text-white has-background-primary" style={{ padding: "3px", borderRadius: "6px" }}><div className="has-text-centered" style={{ width: "3rem", height: "3rem" }}><div className="is-size-7">STEP</div><div className="is-size-4 has-text-weight-bold">{step}</div></div></div>
      </div>
      <div className="column is-size-5 has-text-weight-bold">{title}</div>
    </div>
    <div className="columns is-mobile">
      <div className="column is-narrow is-flex is-hidden-mobile">
        <div className={`is-flex ${Styles.dashline}`} style={{ width: "3rem" }}></div>
      </div>
      <div className="column" style={{ width: "100%", paddingBottom: "1.5rem" }}>
        <ColorBox color="#DBE8EA">{children}</ColorBox>
      </div>
    </div>
  </div>
)

const BigIcon = ({ icon }) => (
  <div className="icon is-large has-text-primary is-hidden-mobile" style={{ margin: ".5rem" }}><i className={`fa-3x ${icon}`} /></div>
)

const DefDesc = ({ title, desc }) => (
  <div className="columns has-text-left">
    <div className="column is-narrow" style={{ Width: "100%", paddingTop: ".5rem", paddingBottom: ".5rem" }}>
      <span className="tag has-background-primary has-text-white" style={{ width: "100%", minWidth: "8rem" }}>{title}</span>
    </div>
    <div className="column is-narrow is-hidden-mobile">…</div>
    <div className="column" style={{paddingTop: ".5rem", paddingBottom: ".5rem"}}>{desc}</div>
  </div>
)

const Subsubsection = ({ title, children }) => (
  <div>
    <h1 className="title has-background-link has-text-white is-6" style={{ margin: "0 -.5rem 1rem", padding: ".5em .75em", borderRadius: "1.5rem", lineHeight: "1.75em" }}>{title}</h1>
    {children}
  </div>
)

const TwoColumn = ({ img, children }) => {
  const image = (
    <figure className={`image ${Styles.isFullOnMobile}`}>
      <img src={img} alt=""/>
    </figure>
  )
  return (
    <div className="columns">
      <div className="column is-hidden-tablet has-text-centered">{image}</div>
      <div className="column is-three-fifths">
        <Text>{children}</Text>
      </div>
      <div className={`column is-hidden-mobile has-text-centered ${Styles.isVcentered}`}>{image}</div>
    </div>
  )
}

// 本文
const Text = ({ className, children }) => (
  <div className={`content has-text-left ${className} ${Styles.text}`}>
    {children}
  </div>
)

// 管理コンソールログイン方法
const HowToLoginConsole = ({ from, description, firstImageSrc, secondImageSrc }) => (
  <Subsubsection title={<Wrap><span>ログイン方法</span><span>（{from}からの場合）</span></Wrap>}>
    <Text>
      <p>{description}</p>
    </Text>
    <div className="columns is-mobile is-vcentered">
      <div className="column is-paddingless"><img src={firstImageSrc} alt=""/></div>
      <div className="column is-narrow is-paddingless"><div className="icon is-small is-size-7-mobile has-text-grey-darker"><i className="fas fa-2x fa-arrow-right" /></div></div>
      <div className="column is-paddingless"><img src={secondImageSrc} alt=""/></div>
    </div>
  </Subsubsection>
)

// 利用開始までの流れ

const GetStartedItem = ({ title, icon, description, isLast }) => {
  const GetStartedDotLineMobile = () => <div className={`is-hidden-tablet is-flex ${Styles.getStartedDotLineMobile}`} style={{ width: "3rem", minHeight: "2rem", flex: "1" }}></div>

  return (
    <div key={title}>
      <div className="is-hidden-mobile">
        <BigIcon icon={icon} />
        <div className="is-size-6 has-text-weight-bold">{title}</div>
        {description && <div className="is-size-7">{description}</div>}
      </div>

      <div className="is-hidden-tablet" style={{ marginBottom: "1rem" }}>
        <div className="columns is-mobile is-vcentered">
          <div className="column is-narrow" style={{ paddingTop: "0", paddingBottom: "0" }}>
            <span className="icon is-medium has-text-primary"><i className={`fa-2x ${icon}`} /></span>
          </div>
          <div className="column has-text-left is-size-6 is-size-7-mobile has-text-weight-bold" style={{ minWidth: "150px" }}>{title}</div>
        </div>
        <div className="columns is-mobile">
          <div className="column is-narrow is-flex" style={{ paddingTop: "0", paddingBottom: "0", marginTop: ".25rem" }}>
            {isLast ? <div style={{ marginLeft: "3rem" }}></div> : <GetStartedDotLineMobile />}
          </div>
          <div className="column has-text-left is-size-7" style={{ padding: 0, marginTop: "-.75em", marginLeft: "-0.2em" }}>{description}</div>
        </div>
        <div className="columns is-mobile">
        </div>
      </div>
    </div>
  )
}

const GetStartedDotLine = () => <div className={`is-hidden-mobile ${Styles.flowline}`} style={{ flex: "1", minWidth: "2.5rem" }}></div>

const GetStarted = ({ type, children }) => (
  <Subsubsection title={<Wrap><span>利用開始までの流れ</span><span>（{type}版）</span></Wrap>}>
    <div className="columns">
      <div className="column is-narrow is-hidden-touch" style={{width: ".25rem"}}></div>
      <div className="column is-flex-tablet is-inline-block-mobile" style={{ marginTop: "1.5rem" }}>
        {children}
      </div>
      <div className="column is-narrow is-hidden-touch" style={{width: ".25rem"}}></div>
    </div>
  </Subsubsection>
)

// スポットへ行く

const GoSpotItemDotLine = () => <div className={`is-hidden-mobile ${Styles.flowline} ${Styles.goSpotItem}`} style={{ flex: "1", minWidth: "1.5rem", marginTop: "0" }}></div>

const GoSpotItem = ({ index, title, src, isLast }) => {
  const DotLineMobile = () => <div className={`is-hidden-tablet is-flex ${Styles.getStartedDotLineMobile}`} style={{ width: "3rem", minHeight: "2rem", flex: "1", marginLeft: "9.5px" }}></div>

  return (
    <div key={title}>
      <div className="is-hidden-mobile">
        <div><img src={src} alt="" style={{ width: "100%", minWidth: "6.25rem", maxWidth: "8rem" }} /></div>
        <div className="tag is-rounded has-background-dark has-text-white">{index}</div>
        <div className="has-text-weight-bold is-size-7 is-size-6-desktop" style={{ marginTop: ".5em" }}>{title}</div>
      </div>

      <div className="is-hidden-tablet" style={{ marginBottom: "1rem" }}>
        <div className="columns is-mobile is-vcentered">
          <div className="column is-narrow is-paddingless" style={{ paddingTop: "0", paddingBottom: "0" }}>
            <img src={src} alt="" style={{ width: "90px" }} />
          </div>
          <div className="column has-text-left">
            <div className="tag is-rounded has-background-dark has-text-white">{index}</div>
            <div className="is-size-6 is-size-7-mobile has-text-weight-bold">{title}</div>
          </div>
        </div>
        <div className="is-flex" style={{ paddingTop: "0", paddingBottom: "0", marginTop: "-1rem", marginBottom: "-1rem" }}>
          {isLast || <DotLineMobile />}
        </div>
      </div>
    </div>
  )
}

//////////////////// コンテンツを配信する ////////////////////

const Content = () => (
  <div id="publisher" style={{ paddingTop: "2rem", marginTop: "-2rem" }}>
    <Mainsection title="コンテンツを配信する">
      <RequiredSection>
        <RequiredItem name="メールアドレス" icon="far fa-envelope" size="is-4" />
        <RequiredItem name="スマートフォン" label="またはタブレット端末" icon="fas fa-mobile-alt" size="is-4" />
        <RequiredItem name="Wi-Fiアクセス情報" label="SSID、パスワード" icon="fas fa-wifi" size="is-4" />
      </RequiredSection>

      <Spacer isHalf />

      <div className="box is-shadowless">
        <Subsection title="ご利用の流れ">
          <Step step="1" title="スマホアプリをインストール">
            <Text className="has-text-left">
              <p>スマホアプリ「ナタデココ」をインストールし、画面の案内に従ってセットアップを完了します</p>
            </Text>
            <AppInstall />
            <Spacer isSmall />
          </Step>

          <Step step="2" title="コンテンツ配信サービスの利用登録">
            <div className="columns">
              <div className="column is-three-fifths">
                <Text>
                  <p>管理コンソールへの初回ログイン時に配信サービスの利用登録が求められます。画面の案内に従ってメールアドレスを入力し、利用登録をおこなってください。</p>
                  <p><span className="has-text-weight-bold">support@natade-coco.com</span> より届くメールをご確認いただいたのち、管理コンソールへのログインが可能となります。</p>
                </Text>
              </div>
              <div className="column">
                <div className="box is-shadowless" style={{ padding: "1rem .5rem" }}>
                  <div className="content has-text-centered">
                    <figure style={{ marginBottom: "0" }}><img src={ConsoleQR} alt="" /></figure>
                    <a className="is-size-7-mobile" href="https://console.natade-coco.com/" style={{ letterSpacing: "-0.08em" }} >https://console.natade-coco.com/</a>
                    <p className="is-size-7 has-text-weight-bold">(推奨ブラウザ：Chrome 最新版, Safari 最新版)</p>
                  </div>
                </div>
              </div>
            </div>
            <Spacer isHalf />
            <HowToLoginConsole
              from="PC"
              description="ログインボタンをクリックすると表示されるログインQRコードを、ナタデココアプリで読み取ります。"
              firstImageSrc={PublisherStep2PC1}
              secondImageSrc={PublisherStep2PC2}
            />
            <Spacer />
            <HowToLoginConsole
              from="モバイル"
              description="ログインボタンをタップするとナタデココアプリが起動し、ログイン処理が実行されます。"
              firstImageSrc={PublisherStep2Mobile1}
              secondImageSrc={PublisherStep2Mobile2}
            />
            <Spacer isSmall />
          </Step>
          
          <Step step="3" title="配信ユニットのお申し込み">
            <Text>
              <p>配信ユニットは管理コンソールからお申し込みいただけます。</p>
              <p>ログイン後、「ユニットの新規作成」ページに移動し、画面の案内に従ってプランを選択、Wi-Fi情報などを入力してお申し込みください。</p>
              <p className="is-size-7">＊Wi-Fi情報は後から変更可能です</p>
            </Text>
            <GetStarted type="クラウド">
              <GetStartedItem title="お申し込み完了" icon="fas fa-user-check" />
              <GetStartedDotLine />
              <GetStartedItem title="利用開始" icon="far fa-check-circle" description="約１０分で準備完了" isLast />
            </GetStarted>
            <Spacer isHalf />
            <GetStarted type="エッジ">
              <GetStartedItem title={<Wrap><span>お申し込み</span><span>完了</span></Wrap>} icon="fas fa-user-check" />
              <GetStartedDotLine />
              <GetStartedItem title={<Wrap><span>配信ユニット</span><span>到着</span></Wrap>} icon="fas fa-truck" />
              <GetStartedDotLine />
              <GetStartedItem title="セッティング" icon="fas fa-plug" description={<Wrap><span>Wi-Fi機器と</span><span>配信ユニットを</span><br />LANケーブルで繋ぎ、<br />電源を入れます</Wrap>} />
              <GetStartedDotLine />
              <GetStartedItem title="利用開始" icon="far fa-check-circle" isLast/>
            </GetStarted>
            <Spacer isSmall />
          </Step>
          <Step step="4" title="コンテンツをアップロード">
            <TwoColumn img={PublisherStep4}>
              <p>コンテンツは管理コンソールからアップロードします。</p>
              <p>ログイン後、各コンテンツのアップロードページに移動し、ファイルの選択やタイトルなどの必要事項を入力します。</p>
              <p>アップロードが完了するとすぐにアクセス用QRコードが発行されます。</p>
            </TwoColumn>
            <Subsubsection title="コンテンツの種類と対応するページ">
              <div className="box is-shadowless" style={{ marginLeft: "-.5rem", marginRight: "-.5rem" }}>
                <DefDesc title="メディアページ" desc="画像・動画音楽ファイルなど" />
                <DefDesc title="アプリページ" desc="ウェブアプリケーション（コンテナイメージ）" />
                <DefDesc title="外部リンクページ" desc="URL・ユニバーサルリンクなど" />
              </div>
            </Subsubsection>
          </Step>
          <Step step="5" title="QRコードを印刷">
            <TwoColumn img={PublisherStep5}>
              <p>コンテンツ詳細ページからQRコードを印刷し、natadeCOCOスポットのエリア内に貼りましょう。</p>
            </TwoColumn>
          </Step>
          <Step step="6" title="公開設定の変更">
            <Text>
              <p>スポットやコンテンツをスマホアプリから検索できるようにして、プロモーションに活用することができます。</p>
            </Text>
            <div className="box is-shadowless">
              <DefDesc title="スポットの設定" desc="ユニット詳細ページ > 位置情報 > マップへの公開をONに" />
              <DefDesc title="コンテンツの設定" desc="コンテンツ詳細ページ > マップへの公開をONに" />
            </div>
          </Step>
        </Subsection>
      </div>

    </Mainsection>
  </div>
)

//////////////////// natadeCOCOスポットを探す ////////////////////

const Search = () => (
  <div id="consumer" style={{ paddingTop: "2rem", marginTop: "-2rem" }}>
    <Mainsection title="natadeCOCOスポットを探す">
      <RequiredSection>
        <RequiredItem name="スマートフォン" label="またはタブレット端末" icon="fas fa-mobile-alt" size="is-12" />
      </RequiredSection>

      <Spacer isHalf />

      <div className="box">
        <Subsection title="ご利用の流れ">
          <Step step="1" title="スマホアプリをインストール">
            <Text>
              <p>スマホアプリ「ナタデココ」をインストールします</p>
            </Text>
            <AppInstall />
          </Step>
          <Step step="2" title="位置情報利用を許可する">
            <TwoColumn img={ConsumerStep2}>
              <p>ナタデココアプリを起動し、画面の案内に従ってセットアップを行います。</p>
              <p>途中で位置情報の利用の許可が求められます。スポットの検索に必要なので、許可をお願いします。</p>
            </TwoColumn>
          </Step>
          <Step step="3" title="スポットを検索する">
            <TwoColumn img={ConsumerStep3}>
              <p>スマホアプリの「スポット」ページを開くと、付近のスポット情報が地図表示されます。</p>
              <p>住所や建物名などでキーワード検索も可能です。また、スポットで配信中のコンテンツ一覧も確認できます。</p>
              <p className="is-size-7">＊Wi-Fiのエリア外にいる場合、まだコンテンツを利用することはできません。</p>
            </TwoColumn>
          </Step>
          <Step step="4" title="スポットへ行く">
            <Text>
              <p>実際にnatadeCOCOスポットのある場所へ行ってWi-Fiに接続すると、コンテンツを利用することができます。</p>
            </Text>
            <div className="columns">
              <div className="column is-narrow is-hidden-touch" style={{ width: ".25rem" }}></div>
              <div className="column is-flex-tablet is-inline-block-mobile has-text-centered" style={{ marginTop: "1.5rem"}} >
                <GoSpotItem index="1" title={<span>スポットへ<br />行く</span>} src={ConsumerStep4_1} />
                <GoSpotItemDotLine />
                <GoSpotItem index="2" title={<span>専用QRコードを<br/>読み取る</span>} src={ConsumerStep4_2} />
                <GoSpotItemDotLine />
                <GoSpotItem index="3" title={<span>アクセスポイントに<br />自動接続</span>} src={ConsumerStep4_3} />
                <GoSpotItemDotLine />
                <GoSpotItem index="4" title={<span>コンテンツが<br />表示される</span>} src={ConsumerStep4_4} isLast />
              </div>
              <div className="column is-narrow is-hidden-touch" style={{ width: ".25rem" }}></div>
            </div>
          </Step>
        </Subsection>
      </div>
    </Mainsection>
  </div>
)

//////////////////// ページ全体 ////////////////////

const Flow = () => (
  <div>
    <Content />
    <Search />
  </div>
)

export default Flow
