import React from "react"
import Header from "src/components/section/header"
import Title from "src/components/section/title"
import Copy from "src/components/section/salescopy"
import Wrap from "src/components/wrap"

const Caption = () => (
  <Header>
    <Title positionStyle="has-text-centered">ご利用の流れ</Title>
    <Copy positionStyle="has-text-centered">
      <Wrap><span>natadeCOCOスポットおよび</span><span>コンテンツ配信サービスの</span><span>ご利用方法をご紹介します。</span></Wrap>
    </Copy>
  </Header>
)

export default Caption
